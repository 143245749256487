import React from 'react';
import {Container} from 'react-bootstrap';

import Layout from '../components/layout';

const PrivacyPolicy = () => (
  <Layout>
    <Container className="my-5">
      <h1 className="mb-4">Privacy Policy</h1>
      <div>
        <section>
          <div>
            <p>
              <i>V.2. Effective: October 1, 2021</i>
            </p>
            <p>
              This Privacy Policy (&apos;Policy&apos;) describes St. Jude Children&apos;s Research
              Hospital, Inc. (&apos;St. Jude,&apos; &apos;we,&apos; &apos;our,&apos; or
              &apos;us&apos;) policies on the collection, use, and disclosure of information in
              connection with your use of any part of St. Jude Cloud, including apps available
              through the St. Jude Cloud (&apos;Apps&apos;) and St. Jude programs (&apos;St. Jude
              Programs&apos;) or any data available there (&apos;St. Jude Data&apos;) (collectively,
              referred to below as the &apos;St. Jude Cloud&apos;). When you use St. Jude Cloud, you
              consent to our collection, use, disclosure, and protection of information as described
              in this Privacy Policy.{' '}
              <b>
                BY ACCESSING OR USING ST. JUDE CLOUD, YOU AGREE TO THIS POLICY. IF YOU DO NOT AGREE
                TO THIS POLICY, YOU MAY NOT USE ST. JUDE CLOUD.
              </b>{' '}
              If you access or use St. Jude Cloud bind yourself and, to the extent allowed, your
              Institution to this Privacy Policy, and your acceptance of will be deemed an
              acceptance by that entity, and &apos;you&apos; and &apos;your&apos; herein shall refer
              to that entity as well as you.
            </p>
            <p>
              This Policy applies only to information collected on or through St. Jude Cloud. It
              does not apply to information collected or obtained through any other means
              (including, without limitation, information collected through other St. Jude websites
              or mobile applications, offline, in person, or from third parties outside St. Jude
              Cloud).
            </p>
            <p>
              Undefined capitalized terms used herein shall have the definitions as set forth in our
              Terms of Use, <a href="/terms-of-use">https://stjude.cloud/terms-of-use</a>. Use of
              the St. Jude Cloud is governed by our Terms of Use and this Privacy Policy.
            </p>
            <h2>Information We Collect</h2>
            <p>
              St. Jude Cloud collects and stores information about you in multiple ways. For
              example, we may collect information that you provide to us, information that we
              automatically collect through your use of St. Jude Cloud or the DNAnexus platform, and
              information from publicly available sources or non-affiliated third parties.
            </p>
            <p>
              <h3>Information You Provide to Us</h3>
            </p>
            <p>
              When you use St. Jude Cloud, you may be invited to provide or upload information that
              St. Jude may access. For example, St. Jude may collect the following illustrative (but
              not exhaustive) types of information when you use St. Jude Cloud:
            </p>
            <ul>
              <li>
                Name, company or organization name, title, address, telephone number, and email
                address;
              </li>
              <li>
                Information supporting your accounts associated with St. Jude Cloud (e.g.,
                information within your St. Jude Cloud account or a DNAnexus account used within St.
                Jude Cloud);
              </li>
              <li>Any forms you fill out on or submit through St. Jude Cloud; </li>
              <li>
                Communications with us through St. Jude Cloud (e.g., St. Jude Cloud support chat or
                email); or
              </li>
              <li>
                Any other information that you choose to provide and/or upload to us through St.
                Jude Cloud.
              </li>
            </ul>
            <p>
              In addition, you may, in various applications within St. Jude Cloud, upload omics
              data, variant data, metadata, other information related to such omics data, or other
              information or files held within the user-specific project on St. Jude Cloud or on
              DNAnexus (collectively, &apos;User Research Data&apos;).St. Jude does not intend to
              access User Research Data, unless You explicitly grant access to St. Jude, provided
              that, however, regardless of your account and sharing settings, St. Jude will have
              access to data accessed through visualizations created through tools made available in
              the St. Jude Apps, including but not limited those made in St. Jude Cloud
              Visualization Community using ProteinPaint and GenomePaint. Also, in rare
              circumstances, St. Jude may work with DNAnexus to gain access and/or remove User
              Research Data. St. Jude reserves the right to access or remove User Research Data if
              necessary, to provide St. Jude Cloud services as offered, for security and
              fraud-prevention purposes, for the protection of St. Jude and its agents, and as
              required by law.
            </p>
            <p>
              In certain Apps, such as St. Jude Cloud Visualization Community, you can use St. Jude
              Cloud tools to create visualizations with your data and share those with collaborators
              or publicly. This data is not stored on St. Jude owned servers, but instead is hosted
              on the DNAnexus Platform and accessed through a URL by St. Jude Cloud.
            </p>
            <p>
              Additionally, you may also provide certain financial account information to DNAnexus
              and/or payment processors to make a payment, including bank account and/or credit card
              information. This information is collected and stored by DNAnexus and/or payment
              processing partners. We do not access, store or collect your financial account or
              credit card information.
            </p>
            <h3>Information that Is Collected Automatically and/or Passively</h3>
            <p>
              In addition to the information you provide to us directly, we may automatically
              collect information about your use of St. Jude Cloud as follows:
            </p>
            <ul>
              <li>
                Device/Usage Information. When you use St. Jude Cloud, we may collect and analyze
                information such as your IP address, browser types, browser language, operating
                system, the state or country from which you accessed St. Jude Cloud, software and
                hardware attributes (including device types and IDs), referring and exit pages and
                URLS, platform type, the number of clicks, files you download, domain names, landing
                pages, pages viewed and the order of those pages, the amount of time spent on
                particular pages, the terms you use in searches on our sites, the date and time you
                used St. Jude Cloud, uploaded content (such as User Data), error logs, and other
                similar information. We use this information (including the information collected by
                our third-party service providers) to make St. Jude Cloud better (including to
                determine which portions of St. Jude Cloud are used most frequently and what our
                Users like or do not like).
              </li>
              <li>
                Emails. If you receive an email from us, we may collect information related to
                whether you open the email, and information describing your interaction with the
                email, including but not limited to which content you viewed.
              </li>
            </ul>
            <h3>Information from Third-Party Sources</h3>
            <p>
              We may receive information about you from publicly and commercially available sources,
              as permitted by law, which we may combine with other information we receive from or
              about you.
            </p>
            <h4>Cookies and Other Technologies</h4>
            <p>
              We and our third-party service providers may use cookies, clear GIFs, pixel tags,
              beacons, and other technologies that help us better understand user behavior,
              personalize preferences, perform research and analytics, and improve the services we
              provide. You may choose to accept or decline certain cookies. Most web browsers
              automatically accept cookies, but your browser may allow you to modify your browser
              settings to decline certain cookies if you prefer. If you disable cookies, you may be
              prevented from accessing or taking full advantage of St. Jude Cloud. To learn more
              about cookies, please visit{' '}
              <a rel="noopener noreferrer" href="https://www.allaboutcookies.org/" target="_blank">
                https://www.allaboutcookies.org/
              </a>
              .
            </p>
            <h4>Web Logs</h4>
            <p>
              In conjunction with the gathering of data through cookies, Web servers may log records
              such as your device type, operating system type, device advertising identifier,
              browser type, domain, and other system settings, as well as the language your system
              uses and the country and time zone where your device is located. The Web server logs
              also may record the address of the Web page that referred you to St. Jude Cloud, the
              IP address (and associated city and state or province for the IP address) of the
              device you use to connect to the Internet, and data about your interaction with St.
              Jude Cloud such as which pages you visit.
            </p>
            <h4>Online Analytics</h4>
            <p>
              We may use third-party web analytics services, such as those provided by Google
              Analytics, Intercom, and Datadog. These service providers use cookies and other 
              technologies described in this Policy to help us analyze how Users use St. Jude 
              Cloud and to provide tutorials and support to Users within St. Jude Cloud. The 
              information collected by these services will be disclosed to or collected directly 
              by theseservice providers.
            </p>
            <p>
              To request prevent Google Analytics from using your information for analytics, a user
              may consult the following:{' '}
              <a rel="noopener noreferrer" href="https://mixpanel.com/optout/" target="_blank">
                https://tools.google.com/dlpage/gaoptout
              </a>
              .
            </p>
            <p>
              If you wish to prevent Intercom and Datadog from using your 
              information for analytics and support, you may configure your browser to block the 
              relevant cookies or outgoing HTTP requests.
            </p>
            <h4>Do Not Track Signals and Similar Mechanisms</h4>
            <p>
              We do not recognize or respond to browser-initiated Do Not Track signals, as the
              Internet industry is currently still working on Do Not Track standards,
              implementations, and solutions.
            </p>
            <h4>User Software and Reference Data</h4>
            <p>
              You may also be permitted to upload your own software and data to St. Jude Cloud in
              the course of using St. Jude Cloud. As set forth in the St. Jude Cloud Terms of Use,
              you agree to and accept full responsibility for obtaining all permissions, consents,
              and rights necessary for uploading and using any such software and data to and with
              St. Jude Cloud.
            </p>
            <h3>How We Use the Information We Collect</h3>
            <p>We may use your information for any of the following reasons:</p>
            <ul>
              <li>For the purposes for which you provide it;</li>
              <li>
                To enable you to use St. Jude Cloud, including evaluating your application for
                access to St. Jude Cloud, registering you for a St. Jude Cloud account, and
                verifying your identity and authority to use St. Jude Cloud;
              </li>
              <li>For customer support and to respond to your inquiries;</li>
              <li>For internal recordkeeping purposes;</li>
              <li>To analyze, improve and maintain St. Jude Cloud and for product development;</li>
              <li>
                To address fraud or safety concerns, or to investigate complaints or suspected fraud
                or wrongdoing;
              </li>
              <li>To provide you with a personalized experience on St. Jude Cloud;</li>
              <li>To contact you with information about your use of St. Jude Cloud;</li>
              <li>For other research and analytical purposes; and</li>
              <li>
                To protect, enforce, or defend the legal rights, privacy, safety, security, or
                property of St. Jude, its employees or agents, or other users, and to comply with
                applicable law.
              </li>
            </ul>
            <p>
              We may combine information that we collect from you through St. Jude Cloud with
              information that we obtain from affiliated and non-affiliated third parties, and
              information derived for any other products or Platforms we provide.
            </p>
            <p>
              We may aggregate and/or de-identify information collected through St. Jude Cloud. We
              may use de-identified or aggregated data for any purpose, including without limitation
              for research and marketing purposes and may also share such data with third parties.
            </p>
            <h4>How We Share Information</h4>
            <p>
              The following provides information about entities with whom we may share information.
              Our practices may vary depending on the type of information.
            </p>
            <p>
              <i>Affiliates and Partners</i>. We may share information with our affiliates.
              Information may also be available to a St. Jude Cloud Partner, such as DNAnexus or
              Microsoft Azure, as described in its privacy policies and terms. See{' '}
              <a rel="noopener noreferrer" href="https://www.dnanexus.com/terms" target="_blank">
                https://www.dnanexus.com/terms
              </a>
              ,{' '}
              <a rel="noopener noreferrer" href="https://www.dnanexus.com/privacy" target="_blank">
                https://www.dnanexus.com/privacy
              </a>
              , and{' '}
              <a
                rel="noopener noreferrer"
                href="https://azure.microsoft.com/en-us/support/legal/"
                target="_blank"
              >
                https://azure.microsoft.com/en-us/support/legal/
              </a>
              .
            </p>
            <p>
              <i>Service Providers.</i> We may share information with third parties that help us
              operate our business and provide our services, such as contractors that provide us
              with technology, services, data, or content.
            </p>
            <p>
              <i>
                Other Parties When Required by Law or as Necessary to Protect Our Users and St. Jude
                Cloud
              </i>
              . We may share information when we believe that doing so is necessary to protect,
              enforce, or defend the legal rights, privacy, safety, or property of St. Jude, our
              employees or agents or users, or to comply with applicable law or legal process,
              including responding to requests from public and government authorities.
            </p>
            <p>
              <i>Business Transfers</i>. We may transfer the information we hold in the event we
              sell or transfer all or a portion of our business or assets (such as in connection
              with a merger, acquisition, reorganization, dissolution or liquidation) or in
              connection with steps we take in anticipation of such a transaction.
            </p>
            <p>
              <i>Aggregated or De-Identified Information.</i> We may share aggregated or
              de-identified data without restriction.
            </p>
            <p>
              <i>Otherwise with Your Consent or at Your Direction</i>. In addition to the sharing
              described in this Policy, we may share information with third parties you consent to
              or direct such sharing.
            </p>
            <h4>Information You Share with Other Users</h4>
            <p>
              St. Jude Cloud allows you to upload and share share omics data, other types of
              research data, visualizations, and other information (&apos;User Data&apos;) you
              submit to or create within St. Jude Cloud with other Users of St. Jude Cloud or
              outside St. Jude Cloud. St. Jude is not responsible for such other Users&apos; or
              individuals&apos; use of User Data that you make available to others, including
              through collaborative, team, publicly accessible links, or public features of St. Jude
              Cloud or by your incorporation of certain data into other fora. Some actions within
              St. Jude Cloud or DNAnexus generate publicly accessible links to your data (e.g.,
              creating a visualization that uses User Data or generating a download URL from
              DNAnexus so that anyone who has access to these links can access the data contained in
              them without further authentication or authorization. You understand and acknowledge
              that, if you choose to share User Data with other Users or individuals, such
              information might be copied or redistributed by those Users or individuals. Even after
              you remove information from your account or delete your account, copies of that User
              Data may remain viewable elsewhere to the extent it has previously been shared with
              others.
            </p>
            <h2>Privacy and Security</h2>
            <h3>Protected Health Information</h3>
            <p>
              YOU AGREE THAT YOU WILL UPLOAD AND/OR USE DATA ONLY AS PERMITTED BY THIS PRIVACY
              POLICY, THE TERMS, AND ST. JUDE CLOUD (SUCH AS GENOMIC AND DE-IDENTIFIED DATA), AND
              YOU MAY NOT AND WILL NOT UPLOAD ANY PROTECTED HEALTH INFORMATION, OR ANY OTHER
              PERSONALLY IDENTIFYING INFORMATION, PERSONAL INFORMATION, OR PERSONAL DATA, AS DEFINED
              BY APPLICABLE LAW IN THE UNITED STATES OR YOUR COUNTRY OF RESIDENCE OR THE COUNTRY AND
              LOCATION FROM WHICH THE DATA IS UPLOADED TO ST. JUDE CLOUD.
            </p>
            <h3>Children&apos;s Privacy</h3>
            <p>
              We do not knowingly collect any personal information on St. Jude Cloud directly from
              children under the age of 13 without parental consent, unless permitted by law. If we
              learn that a child under the age of 13 has provided us with personal information (as
              defined by the Children&apos;s Online Privacy Protection Act), we will delete it in
              accordance with applicable law.
            </p>
            <h3>Security</h3>
            <p>
              We employ administrative, technical, and physical security measures to help protect
              information from unauthorized access. These measures vary depending on the sensitivity
              of the information we have collected. However, no method of transmission over the
              Internet or via mobile device, or method of electronic storage, is absolutely secure.
              Therefore, while we strive to use commercially acceptable means to protect your
              information, we cannot and do not guarantee its protection.
            </p>
            <h3>Third Party Links and Services</h3>
            <p>
              St. Jude Cloud may contain links to third-party websites or services. If you choose to
              use these sites or services, you may disclose your information not just to those third
              parties, but also to their users and the public more generally depending on how their
              services function. Because these third-party websites and services are not operated by
              St. Jude, St. Jude is not responsible for the content or practices of those websites
              or services. The collection, use, and disclosure of your personal and other
              information will be subject to the privacy policies of the third party websites or
              services, and not this Policy. We urge you to read the privacy and security policies
              of these third parties.
            </p>
            <h3>California Residents: Your California Privacy Rights</h3>
            <p>
              St. Jude does not disclose your personal information to third parties for the purpose
              of directly marketing their services to you unless you first agree to such disclosure.
            </p>
            <h3>How to Access or Update Your Information &amp; Other Privacy Choices Available</h3>
            <p>
              <i>Manage Your Account Information.</i> You may modify your St. Jude Cloud account
              information by logging into the relevant St. Jude Cloud app and navigating to the
              profile section. You may modify your DNAnexus account information by logging into your
              account through{' '}
              <a rel="noopener noreferrer" href="https://platform.dnanexus.com" target="_blank">
                https://platform.dnanexus.com
              </a>
              .
            </p>
            <p>
              <i>Cookies and Other Automated Technologies.</i> For information about opt-out options
              relating to data collected using cookies and other automated technologies (e.g., for
              analytics), please review the information and hyperlinks in the &apos;Cookies and
              Other Technologies,&apos; and &apos;Online Analytics&apos; sections of this Privacy
              Policy.
            </p>
            <h3>International Users and Consent to Transfer to Us</h3>
            <p>
              Our computer systems are currently based in the United States, so your data will be
              processed by us in the United States. If you create an account with St. Jude Cloud as
              a visitor from outside the United States, by using St. Jude Cloud, you agree to this
              Policy, and you consent to the transfer of all such information to the United States
              and to the processing of that information as described in this Policy, and you
              represent and warrant that you have obtained all appropriate consents from the person
              who owns the data and the person from whose tissue the data was derived for the
              processing and storage of the data in the United States.
            </p>
            <h3>Modifications to This Policy</h3>
            <p>
              Modifications or changes to the Policy will be reflected in the most current Privacy
              Policy, which is available through St. Jude Cloud. We reserve the right to modify this
              Policy at any time, so we encourage you to review it frequently. Your continued use of
              St. Jude Cloud following any changes signifies your acceptance of our Privacy Policy
              as modified.
            </p>
            <h3>Contact Us</h3>
            <p>
              If you wish to provide feedback to us regarding St. Jude Cloud, please contact us{' '}
              <a
                href="https://hospital.stjude.org/apps/forms/fb/st-jude-cloud-contact/"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://stjude.cloud/contact
              </a>
              .
            </p>
            <p>
              If you have any questions about St. Jude Children&apos;s Research Hospital or our
              privacy protections, please contact us at{' '}
              <a href="mailto: hipaaprivacy@stjude.org">hipaaprivacy@stjude.org</a>.
            </p>
          </div>
        </section>
      </div>
    </Container>
  </Layout>
);

export default PrivacyPolicy;
